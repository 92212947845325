import {
  BeginTrialResponse,
  CheckTrialStatusResponse,
  DeleteNotificationFilterRequest,
  GetNotificationFiltersResponse,
  GetUserNotificationsResponse,
  GetUserNotificationsV3Response,
  Profile,
  PutNotificationFilterRequest,
  PutResponse,
  SignInResponse,
  SignOutResponse,
  UpdateNotificationFilterRequest,
  UpdateNotificationStatusRequest,
  UpdateNotificationStatusResponse,
  ValidateTokenResponse,
} from '@interfaces/account.interface';
import { Offering } from '@interfaces/admin.interface';
import {
  Badge,
  ContentFilter,
  GetCountriesResponse,
  Organization,
  PostCategory,
  PostHashTag,
  RagQuery,
  RagResponse,
} from '@interfaces/common.interface';
import {
  AddNotePostRequest,
  ContentConfig,
  KnowledgeArea,
  SavePosts,
  SummaryCollection,
} from '@interfaces/content.interface';
import { LibraryCollection, LibraryItem } from '@interfaces/library.interface';
import { GetNewsFeedCollectionRequest, NewsFeedCollection } from '@interfaces/news_feed.interface';
import {
  GetFeedResponse,
  GetFeedSearchRequest,
  GetFeedSearchResponse,
  Post,
  PostItem,
} from '@interfaces/post.interface';
import { Summary } from '@interfaces/summary.interface';
import { WorkspaceDomain, WorkspaceInvite, Workspaces, WorkspaceUser } from '@interfaces/workspace.interface';
import { CancelTokenSource } from 'axios';
import { Author } from 'next/dist/lib/metadata/types/metadata-types';
import {
  ChatbotHistoryRequest,
  ChatbotHistoryRespond,
  ChatbotRequest,
  ChatbotRespond,
  fileUploadRequest,
  fileUploadRespond,
  SaveChatbotMessageRequest,
  SaveChatbotMessageRespond,
  saveUserFileRequest,
  saveUserFileRespond,
} from './chatbot.interface';
import {
  BillingInfoResponse,
  GetInvoicesResponse,
  UpdateBillingInfoRequest,
  UpdateBillingInfoResponse,
} from './payment.interface';

export abstract class BaseApi {
  abstract getSubscriptions(): Promise<Offering[]>;
  //#region Account APIs
  abstract signIn(email: string, password: string): Promise<SignInResponse>;

  abstract signOut(): Promise<SignOutResponse>;
  abstract validateToken(): Promise<ValidateTokenResponse>;
  abstract getProfile(): Promise<Profile>;
  abstract updateProfile(profile: any): Promise<Profile>;
  abstract getAuthors(): Promise<Author[]>;
  abstract beginTrial(): Promise<BeginTrialResponse>;
  abstract checkTrialStatus(): Promise<CheckTrialStatusResponse>;
  abstract deleteAccount(): Promise<boolean>;
  abstract resetPassword(): Promise<boolean>;
  abstract getPricingTable(): Promise<[]>;

  //#endregion

  // Feed
  abstract getFeedSearch(request: GetFeedSearchRequest): Promise<GetFeedSearchResponse>;

  // chunk Data
  abstract getSearchChunks(
    request: GetFeedSearchRequest,
    cancelTokenSource?: CancelTokenSource
  ): Promise<GetFeedSearchResponse>;

  //#region content APIs

  /// Return sections for content tab
  /// Response will include sub-sections
  abstract getContentConfig(): Promise<ContentConfig>;
  abstract getFeedListing(
    page_number: number,
    filters?: {
      authors?: Author[];
      badges?: Badge[];
      sectors?: PostCategory[];
      hashtags?: PostHashTag[];
    }
  ): Promise<GetFeedResponse>;
  abstract getPost(id?: string, itemUrl?: string): Promise<PostItem>;
  abstract putPost(post: Post): Promise<string>;
  abstract getSummaryCollection(id: string): Promise<SummaryCollection>;
  abstract getSummary(id: string): Promise<Summary>;
  abstract getLexcDocument(id: string): Promise<Document>;
  abstract getNewsFeedCollection(
    getNewsFeedCollectionRequest: GetNewsFeedCollectionRequest
  ): Promise<NewsFeedCollection>;
  abstract getBadges(): Promise<Badge[]>;
  abstract getCategories(): Promise<PostCategory[]>;
  abstract getHashtags(): Promise<PostHashTag[]>;
  abstract getCollections(lastId: string, pageSize?: number): Promise<LibraryCollection[]>;

  abstract getLibraryListing(
    lastId: string,
    page_size?: number,
    filter?: ContentFilter,
    fetchCollections?: boolean
  ): Promise<LibraryCollection[]>;

  abstract getSummaries(
    lastPostId: string,
    filters?: {
      authors?: Author[];
      badges?: Badge[];
      sectors?: PostCategory[];
      hashtags?: PostHashTag[];
      search_term?: string;
    },
    pageSize?: number
  ): Promise<LibraryItem[]>;

  //#endregion

  //#region Knowledge Area APIs
  abstract addSavedPost(post: Post, areas: KnowledgeArea[], areaId?: string): Promise<boolean>;
  abstract addNoteToArea(noteData: AddNotePostRequest): Promise<boolean>;
  abstract removeSavedPost(saveId: string, post?: Post): Promise<boolean>;
  abstract getKnowledgeAreas(areaId?: string): Promise<KnowledgeArea[]>;
  abstract getSavedPosts(areaId?: string): Promise<SavePosts[]>;
  abstract putKnowledgeArea(knowledgeArea: KnowledgeArea): Promise<KnowledgeArea>;
  abstract deleteKnowledgeArea(areaId: string): Promise<boolean>;
  //#endregion

  //#region Workspace APIs
  abstract getUserWorkspace(): Promise<Workspaces>;
  abstract getWorkspaceInfo(workspaceId: number): Promise<Workspaces>;
  abstract createWorkspace(workspace: Workspaces): Promise<Workspaces>;
  abstract updateWorkspace(workspace: Workspaces): Promise<Workspaces>;

  abstract addWorkspaceUser(workspaceUser: WorkspaceUser): Promise<WorkspaceUser>;
  abstract removeWorkspaceUser(workspaceUser: WorkspaceUser): Promise<boolean>;

  abstract addWorkspaceDomain(domain: WorkspaceDomain): Promise<WorkspaceDomain>;
  abstract removeWorkspaceDomain(domain: WorkspaceDomain): Promise<boolean>;

  abstract getWorkspaceInvites(): Promise<WorkspaceInvite[]>;
  abstract acceptWorkspaceInvite(workspaceId: string): Promise<boolean>;
  abstract rejectWorkspaceInvite(workspaceId: string): Promise<boolean>;
  abstract leaveWorkspace(workspaceId: string): Promise<boolean>;

  //#region Organization APIs
  abstract getOrganizations(): Promise<Organization[]>;
  abstract getOrganization(urlSlug: string): Promise<Organization>;

  //#endregion

  //#billing APIs
  abstract getBillingInfo(): Promise<BillingInfoResponse>;
  abstract updateBillingInfo(billingData: UpdateBillingInfoRequest): Promise<UpdateBillingInfoResponse>;
  abstract getInvoices(): Promise<GetInvoicesResponse>;
  //#endbilling

  //#Notification APIs
  abstract getNotifications(): Promise<GetUserNotificationsResponse>;
  abstract getNotificationsV3(): Promise<GetUserNotificationsV3Response>;
  abstract updateNotificationStatus(
    notificationId: UpdateNotificationStatusRequest
  ): Promise<UpdateNotificationStatusResponse>;
  //#common APIs
  abstract getCountries(): Promise<GetCountriesResponse>;
  //#endcommon

  // Notification API

  abstract getNotificationFilters(): Promise<GetNotificationFiltersResponse>;

  abstract addNotificationFilter(filter: PutNotificationFilterRequest): Promise<PutResponse>;

  abstract updateNotificationFilter(filter: UpdateNotificationFilterRequest): Promise<PutResponse>;

  abstract deleteNotificationFilter(filterId: DeleteNotificationFilterRequest): Promise<PutResponse>;

  // Notification API over

  abstract ragQueryApiProxy(query: RagQuery): Promise<RagResponse>;

  //search
  abstract getSearchFeedListing(data: GetFeedSearchRequest, metadata?: any): Promise<GetFeedSearchResponse>;

  // Chatbot
  abstract getMonthlyQuota(): Promise<number | null>;

  abstract chatbotMessage(req: ChatbotRequest): Promise<ChatbotRespond>;

  abstract getChatHistory(req: ChatbotHistoryRequest): Promise<ChatbotHistoryRespond>;

  abstract saveChatbotMessage(req: SaveChatbotMessageRequest): Promise<SaveChatbotMessageRespond>;

  abstract saveUserFile(req: saveUserFileRequest): Promise<saveUserFileRespond>;

  abstract fileUpload(req: fileUploadRequest): Promise<fileUploadRespond>;
}
