import { SignInStatus } from '@enums/signin-status.enum';
import { SignupResultErr } from '@interfaces/common.interface';
import { CognitoUser, CognitoUserSession, ISignUpResult } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { ToastOptions, toast } from 'react-toastify';

const toastOptions: ToastOptions = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  progress: undefined,
  theme: 'light',
};

const checkVerifiedStatus = (): string => {
  return localStorage.getItem('verified');
};
const getSignedInUser = async (): Promise<CognitoUser> => Auth.currentAuthenticatedUser();

const getCurrentSession = async (): Promise<CognitoUserSession> => Auth.currentSession();

const signUp = async (email: string, password: string): Promise<ISignUpResult | SignupResultErr> => {
  try {
    const result: ISignUpResult = await Auth.signUp({
      username: email,
      password: password,
      attributes: {
        email: email,
      },
      autoSignIn: {
        enabled: true,
      },
    });
    if (typeof window !== undefined) {
      localStorage.setItem('email', email);
      localStorage.setItem('verified', `${result.userConfirmed}`);
    }
    return result;
  } catch (error) {
    if (error.code === 'NetworkError' || error.code === 'UsernameExistsException')
      return { code: error.code, message: error.message };
  }
  return null;
};

const verifyEmail = async (code: string): Promise<boolean> => {
  try {
    let email: string = '';
    if (typeof window != undefined) {
      email = localStorage.getItem('email');
    }
    if (email == null) {
      return false;
    }

    let result = await Auth.confirmSignUp(email, code);
    console.log(result);
    if (result == 'SUCCESS') {
      localStorage.setItem('verified', 'true');
    }
    return result == 'SUCCESS';
  } catch (error) {
    console.log('error verifying email', error);
    return false;
  }
};

const resendConfirmationCode = async (): Promise<boolean> => {
  try {
    let email: string = '';
    if (typeof window != undefined) {
      email = localStorage.getItem('email');
    }
    await Auth.resendSignUp(email);
    return true;
  } catch (err) {
    console.log('error resending code: ', err);
    return false;
  }
};

const signIn = async (email: string, password: string): Promise<SignInStatus> => {
  try {
    const user: CognitoUser = await Auth.signIn(email, password);
    if (user.challengeName == 'NEW_PASSWORD_REQUIRED') {
      return SignInStatus.NEW_PASSWORD_REQUIRED;
    }

    if (typeof window !== undefined) {
      localStorage.setItem('username', user.getUsername());
      const accessToken = user.getSignInUserSession().getAccessToken().getJwtToken();
      localStorage.setItem('auth-token', accessToken);
    }
    return SignInStatus.SUCCESS;
  } catch (error) {
    if (error.name == 'UserNotConfirmedException') {
      localStorage.setItem('email', email);
      localStorage.setItem('verified', 'false');
      return SignInStatus.USER_NOT_VERIFIED;
    }

    if (error.name == 'NotAuthorizedException') {
      return SignInStatus.INCORRECT_PASSWORD;
    }
    console.log('error signing in', error);
    return SignInStatus.FAIL;
  }
};

const newPasswordChallenge = async (email: string, oldPassword: string, newPassword: string): Promise<boolean> => {
  try {
    const user: CognitoUser = await Auth.signIn(email, oldPassword);
    return new Promise<boolean>((resolve, reject) => {
      user.getUserAttributes((err, data) => {
        user.completeNewPasswordChallenge(newPassword, data, {
          onSuccess: async (result) => {
            console.log('Success', result);
            await signOut();
            return resolve(true);
          },
          newPasswordRequired: (result) => {
            console.log('newPasswordRequired', result);
            return reject(result);
          },
          onFailure: (err) => {
            throw err;
          },
        });
      });
    });
  } catch (error) {
    console.log('error changing password', error);
    return false;
  }
};

const signOut = async (): Promise<void> => {
  try {
    await Auth.signOut();
    if (typeof window !== undefined) {
      localStorage.clear();
    }
    console.log(`SignOut successful`);
  } catch (error) {
    console.log('error signing out', error);
  }
};

const changePassword = async (oldPassword: string, newPassword: string) => {
  try {
    let currentUser: CognitoUser = await Auth.currentAuthenticatedUser();
    let result = await Auth.changePassword(currentUser, oldPassword, newPassword);
    return result;
  } catch (error) {
    console.log('error changing password', error);
  }
};

const forgotPassword = async (email: string) => {
  try {
    // Send confirmation code to user's email
    const result = await Auth.forgotPassword(email);
    toast.info(
      `A verification code is sent to ${email}. Please check your spam folder in case you don't see it in your inbox.`,
      toastOptions
    );
    return;
  } catch (error) {
    toast.error(error.message, toastOptions);
    return;
  }
};

const forgotPasswordChallenge = async (username: string, code: string, new_password: string): Promise<string> => {
  try {
    // Collect confirmation code and new password, then
    return await Auth.forgotPasswordSubmit(username, code, new_password);
  } catch (error) {
    return null;
  }
};

export {
  changePassword,
  checkVerifiedStatus,
  forgotPassword,
  forgotPasswordChallenge,
  getCurrentSession,
  getSignedInUser,
  newPasswordChallenge,
  resendConfirmationCode,
  signIn,
  signOut,
  signUp,
  verifyEmail,
};
