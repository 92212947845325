export class Routes {
  static index: string = '/';
  static home: string = '/home';
  static news: string = '/news';
  static library: string = '/library';
  static act: string = '/act';
  static intelligence: string = '/intelligence';
  static knowledgeHub: string = '/knowledge-hub';
  static profile: string = '/profile';
  static summaryCollection: string = '/summary-collection';
  static pricing: string = '/pricing';
  static organizationDirectory = '/organizations';

  static settings: string = '/settings';
  static addResources: string = `${this.settings}/additional-resources`;
  static account: string = `${this.settings}/account`;
  static manageWorkspace: string = `${this.settings}/workspace`;
  static billing: string = `${this.settings}/billing`;

  static login: string = '/login';
  static register: string = '/register';
  static verify: string = '/verify';
  static onboarding: string = '/onboarding';
  static logout: string = '/logout';
  static error404: string = '/404';

  static contact: string = 'https://lexai.co/kontakt';
  static imprint: string = 'https://lexai.co/imprint';
  static privacy: string = 'https://lexai.co/privacy';
  static terms: string = 'https://lexai.co/termsofuse';
}
