/*
 * Account interfaces
 */

import { Author, Badge, Image, PostCategory, PostHashTag } from '@interfaces/common.interface';

export interface SignUpRequest {
  email?: string;
  password?: string;
}

export interface SignUpResponse {
  success?: boolean;
}

export interface SignInRequest {
  email?: string;
  password?: string;
}

export interface SignInResponse {
  token?: string;
  account_id?: string;
}

export interface ValidateTokenRequest {}

export interface ValidateTokenResponse {
  success?: boolean;
  email?: string;
}

export interface SignOutRequest {}

export interface SignOutResponse {
  success?: boolean;
}

export interface DeleteAccountRequest {}

export interface DeleteAccountResponse {
  success: boolean;
}

export interface GetAuthorsRequest {}

export interface GetAuthorsResponse {
  authors: Author[];
}

export interface BeginTrialRequest {}

export interface BeginTrialResponse {
  status?: TrialStatus;
  message?: string;
}

export interface CheckTrialStatusRequest {}

export interface CheckTrialStatusResponse {
  status?: TrialStatus;
}

/*
 * Profile interfaces
 */

export interface GetProfileRequest {
  account_id?: string;
}

export interface GetProfileResponse {
  profile?: Profile;
}

export interface UpdateProfileRequest {
  profile?: Profile;
}

export interface UpdateProfileResponse {
  profile?: Profile;
}

export interface Profile {
  id?: string;
  first_name?: string;
  last_name?: string;
  profile_image?: Image;
  interests?: Interests;
  email?: string;
  cognito_id?: string;
}

export interface AddInterestRequest {
  interests: Interests;
}

export interface AddInterestResponse {
  success?: boolean;
}

export interface RemoveInterestRequest {
  interests: Interests;
}

export interface RemoveInterestResponse {
  success?: boolean;
}

export interface Interests {
  categories?: PostCategory[];
  hashtags?: PostHashTag[];
  badges?: Badge[];
}

export interface GetUserNotificationsRequest {}

export interface GetUserNotificationsResponse {
  notifications: Notification[];
}

export interface UpdateNotificationStatusRequest {
  notification_id: string;
}

export interface UpdateNotificationStatusResponse {
  success: boolean;
}

export interface Notification {
  id: string;
  title: string;
  contents: string;
  redirect_url: string;
  read: boolean;
}

export interface NotificationV3 {
  notif_id: string;
  filter_id: string;
  text: string;
  redirect_url: string; // post url
  is_read: boolean;
}

export interface NotificationFilterGroup {
  filter_id: number;
  filter_name: string;
  notifications: NotificationV3[];
}

export interface NotificationDateGroup {
  date: string;
  filters: NotificationFilterGroup[];
  total: number;
}

export interface GetUserNotificationsV3Request {}
export interface GetUserNotificationsV3Response {
  notifications: NotificationDateGroup[];
}

export enum TrialStatus {
  UNKNOWN = -1,
  NOT_STARTED = 0,
  ACTIVE,
  EXPIRED,
}

// Notification Interface

export interface NotificationFilter {
  // arrays of ids used to find the rows in db
  filter_id?: number;
  is_notification_enabled: boolean;
  is_email_enabled: boolean; // notification by email
  name: string; // name of the saved filter
  search_term?: string; // searched value in filter
  collection_ids?: number[];
  author_org_ids?: number[];
  hashtag_ids?: number[];
  category_ids?: number[];
  badge_ids?: number[];
}
export interface GetNotificationFiltersRequest {}

export interface GetNotificationFiltersResponse {
  filters: NotificationFilter[];
}

export interface PutNotificationFilterRequest {
  filter: NotificationFilter;
}

export interface PutResponse {
  success: boolean;
  id: number;
}

export interface UpdateNotificationFilterRequest {
  filter: NotificationFilter;
}

export interface DeleteNotificationFilterRequest {
  filter_id: number;
}
