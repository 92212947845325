import { getCurrentSession } from '@lib/amplify-auth';
import { Urls } from '@lib/utils/urls';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { toast } from 'react-toastify';

export class StripeApi {
  private static _instance: StripeApi;
  private _stripePromise = null;
  private headers = {
    'Client-Type': 'lexai-webapp',
    'Client-Version': process.env.NEXT_PUBLIC_APP_VERSION,
    'Client-OS-Type': 'web',
    'Client-OS-Version': '',
    'Client-Device': '',
  };

  private constructor() {}

  public static get Instance(): StripeApi {
    if (!this._instance) {
      this._instance = new StripeApi();
    }
    return this._instance;
  }

  getStripe(): Promise<Stripe> {
    if (!this._stripePromise) {
      this._stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);
    }
    return this._stripePromise;
  }

  async redirectToCheckout(id: string, workspaceId: number) {
    const session = await getCurrentSession();
    const token = session.getAccessToken().getJwtToken();
    const url: string = Urls.createCheckoutSession;

    const res = await axios.post(
      url,
      {
        workspace_id: workspaceId,
        items: [
          {
            price: id,
            quantity: 1,
          },
        ],
      },
      {
        headers: { ...this.headers, 'auth-token': token },
      }
    );

    const stripe = await this.getStripe();

    await stripe.redirectToCheckout({ sessionId: res.data.id });
  }

  async getSubscriptions(workspaceId: string) {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      const url: string = Urls.fetchSubscriptionData;

      const res = await axios.post(
        url,
        { workspace_id: workspaceId },
        {
          headers: { ...this.headers, 'auth-token': token },
        }
      );
      if (res.status !== 200) {
        return null;
      }
      return res.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  async openCustomerPortal(workspaceId: string): Promise<boolean> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      const url: string = Urls.manageBilling;

      const res = await axios.post(
        url,
        { workspace_id: workspaceId },
        {
          headers: { ...this.headers, 'auth-token': token },
        }
      );
      if (res.status != 200) {
        toast.error('Customer not found');
        return false;
      }
      window.location.href = res.data.url;
      return true;
    } catch (err) {
      return false;
    }
  }
}
