import React from 'react';
import '@styles/globals.css';
import '@styles/react-datepicker.css';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';

import de from '@lang/de.json';
import en from '@lang/en.json';
import fr from '@lang/fr.json';
import AppStateProvider from '@store/AppProvider';
import { Amplify } from 'aws-amplify';
import { IntlProvider } from 'react-intl';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);

function getDirection(locale: string | undefined): string {
  if (locale === 'ar') {
    return 'rtl';
  }

  return 'ltr';
}

export default function App({ Component, pageProps }: AppProps) {
  const { locale } = useRouter();

  const messages = {
    en,
    de,
    fr,
  };

  return (
    <main>
      <IntlProvider key={locale} locale={locale} messages={messages[locale]}>
        <AppStateProvider>
          <Component {...pageProps} dir={getDirection(locale)} />
        </AppStateProvider>
        <ToastContainer />
      </IntlProvider>
    </main>
  );
}
