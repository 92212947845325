import { AppState } from '@interfaces/context.interface';
import { Context, createContext } from 'react';

const defaultHistoryFilters = {
  collections: [],
  authors: [],
  hashtags: [],
  categories: [],
  badges: [],
  start_date: '',
  end_date: '',
  search_term: '',
};

const AppStateContext: Context<AppState> = createContext<AppState>({
  isLoading: false,
  profileInfo: {},
  workspaceInfo: {},
  notificationInfo: {
    isLoading: false,
    notifDateGroups: [],
    updateNotificationId: [],
    showToastMsg: false,
  },
  billingInfo: {
    trialStatus: null,
    subscriptionStatus: null,
  },
  isTrialUser: false,
  isPayingUser: false,
  updateProfileInfo: () => {},
  updateLoadingState: () => {},
  updateWorkspaceInfo: () => {},
  setNotificationInfo: () => {},
  summaryHistory: {
    prevSummaryId: '',
    totalData: Number.parseInt(process.env.NEXT_PUBLIC_PAGINATION_SIZE),
    filters: defaultHistoryFilters,
  },
  setSummaryHistory: () => {},
  userDocumentHistory: {
    prevUserDocumentId: '',
    totalData: Number.parseInt(process.env.NEXT_PUBLIC_PAGINATION_SIZE),
    filters: defaultHistoryFilters,
  },
  setUserDocumentHistory: () => {},
  isUserHasEnterprisePlan: false,
  chatbotController: { isOpen: false, doc: {} },
  updateChatbotDoc: () => {},
  triggerChatbotDisplay: () => {},
  monthlyQuota: 0,
});

export default AppStateContext;
