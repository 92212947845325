import { SubscriptionTier } from '@enums/subscription-type.enum';
import { Profile } from '@interfaces/account.interface';
import { Image } from '@interfaces/common.interface';

export interface GetUserWorkspaceRequest {}

export interface GetUserWorkspaceResponse {
  workspace: Workspaces;
}

export interface GetWorkspaceInfoRequest {
  workspace_id: string;
}

export interface GetWorkspaceInfoResponse {
  workspace: Workspaces;
}

export interface CreateWorkspaceRequest {
  workspace: Workspaces;
}

export interface CreateWorkspaceResponse {
  workspace: Workspaces;
}

export interface UpdateWorkspaceRequest {
  workspace: Workspaces;
}

export interface UpdateWorkspaceResponse {
  workspace: Workspaces;
}

export interface Workspaces {
  workspace_id?: string;
  workspace_name?: string;
  workspace_image?: Image;
  workspace_url?: string;
  workspace_domains?: WorkspaceDomain[];
  workspace_users?: WorkspaceUser[];
  isEnterprise?: boolean; // depracated, use subscription_tier instead
  subscription_tier?: SubscriptionTier;
  remaining_ai_quota_percentage?: number;
  feature_flags?: string[];
}

// workspace user messages

export interface AddWorkspaceUserRequest {
  workspace_user: WorkspaceUser;
}

export interface AddWorkspaceUserResponse {
  workspace_user?: WorkspaceUser;
}

export interface RemoveWorkspaceUserRequest {
  workspace_user: WorkspaceUser;
}

export interface RemoveWorkspaceUserResponse {
  success: boolean;
}

export interface WorkspaceUser {
  workspace_id?: string;
  profile?: Profile;
  access?: WorkspaceAccess;
}

// workspace domain messages

export interface AddWorkspaceDomainRequest {
  domain: WorkspaceDomain;
}

export interface AddWorkspaceDomainResponse {
  domain: WorkspaceDomain;
}

export interface RemoveWorkspaceDomainRequest {
  domain: WorkspaceDomain;
}

export interface RemoveWorkspaceDomainResponse {
  success: boolean;
}

export interface WorkspaceDomain {
  domain_id: string;
  workspace_id: string;
  domain_email: string;
}

export interface GetWorkspaceInvitesRequest {}

export interface GetWorkspaceInvitesResponse {
  invites: WorkspaceInvite[];
}

export interface WorkspaceInvite {
  winvite_id: string;
  workspace: Workspaces;
  winvite_email: string;
}

export interface AcceptWorkspaceInviteRequest {
  workspace_id: string;
}

export interface AcceptWorkspaceInviteResponse {
  success: boolean;
}

export interface RejectWorkspaceInviteRequest {
  workspace_id: string;
}

export interface RejectWorkspaceInviteResponse {
  success: boolean;
}

export interface LeaveWorkspaceRequest {
  workspace_id: string;
}

export interface LeaveWorkspaceResponse {
  success: boolean;
}

export enum WorkspaceAccess {
  OWNER,
  ADMIN,
  MEMBER,
}
