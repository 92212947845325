import { BaseApi } from '@interfaces/base-api.interface';
import { getCurrentSession } from '@lib/amplify-auth';
import { Urls } from '@lib/utils/urls';

import {
  BeginTrialRequest,
  BeginTrialResponse,
  CheckTrialStatusRequest,
  CheckTrialStatusResponse,
  DeleteAccountRequest,
  DeleteAccountResponse,
  DeleteNotificationFilterRequest,
  GetAuthorsRequest,
  GetAuthorsResponse,
  GetNotificationFiltersRequest,
  GetNotificationFiltersResponse,
  GetProfileRequest,
  GetProfileResponse,
  GetUserNotificationsResponse,
  GetUserNotificationsV3Request,
  GetUserNotificationsV3Response,
  Profile,
  PutNotificationFilterRequest,
  PutResponse,
  SignInRequest,
  SignInResponse,
  SignOutRequest,
  SignOutResponse,
  UpdateNotificationFilterRequest,
  UpdateNotificationStatusRequest,
  UpdateNotificationStatusResponse,
  UpdateProfileRequest,
  UpdateProfileResponse,
  ValidateTokenRequest,
  ValidateTokenResponse,
} from '@interfaces/account.interface';
import { GetSubscriptionsResponse, Offering } from '@interfaces/admin.interface';
import {
  ChatbotHistoryRequest,
  ChatbotHistoryRespond,
  ChatbotRequest,
  ChatbotRespond,
  fileUploadRequest,
  fileUploadRespond,
  SaveChatbotMessageRequest,
  SaveChatbotMessageRespond,
  saveUserFileRequest,
  saveUserFileRespond,
} from '@interfaces/chatbot.interface';
import {
  Author,
  Badge,
  BasicRequest,
  BasicResponse,
  ContentFilter,
  GetCountriesRequest,
  GetCountriesResponse,
  Organization,
  PostCategory,
  PostHashTag,
  RagQuery,
  RagResponse,
} from '@interfaces/common.interface';
import {
  AddNotePostRequest,
  AddSavedPostRequest,
  AddSavedPostResponse,
  ContentConfig,
  DeleteKnowledgeAreaRequest,
  GetContentConfigRequest,
  GetContentConfigResponse,
  GetDocumentRequest,
  GetKnowledgeAreasRequest,
  GetKnowledgeAreasResponse,
  GetSavedPostsRequest,
  GetSavedPostsResponse,
  GetSummaryCollectionRequest,
  GetSummaryCollectionResponse,
  GetSummaryRequest,
  GetSummaryResponse,
  KnowledgeArea,
  PutKnowledgeAreaRequest,
  PutKnowledgeAreaResponse,
  RemoveSavedPostRequest,
  RemoveSavedPostResponse,
  SavePosts,
  SummaryCollection,
} from '@interfaces/content.interface';
import {
  GetCollectionsRequest,
  GetCollectionsResponse,
  GetLibraryListingRequest,
  GetLibraryListingResponse,
  GetSummariesRequest,
  GetSummariesResponse,
  LibraryCollection,
  LibraryItem,
} from '@interfaces/library.interface';
import {
  GetNewsFeedCollectionRequest,
  GetNewsFeedCollectionResponse,
  NewsFeedCollection,
} from '@interfaces/news_feed.interface';
import {
  GetOrganizationRequest,
  GetOrganizationResponse,
  GetOrganizationsRequest,
  GetOrganizationsResponse,
} from '@interfaces/organization.interface';
import {
  BillingInfoRequest,
  BillingInfoResponse,
  GetInvoicesRequest,
  GetInvoicesResponse,
  UpdateBillingInfoRequest,
  UpdateBillingInfoResponse,
} from '@interfaces/payment.interface';
import {
  GetBadgeRequest,
  GetBadgeResponse,
  GetCategoryRequest,
  GetCategoryResponse,
  GetFeedRequest,
  GetFeedResponse,
  GetFeedSearchRequest,
  GetFeedSearchResponse,
  GetHashtagRequest,
  GetHashtagResponse,
  GetPostRequest,
  GetPostResponse,
  Post,
  PostItem,
  PutPostRequest,
} from '@interfaces/post.interface';
import { Summary } from '@interfaces/summary.interface';
import {
  AcceptWorkspaceInviteRequest,
  AcceptWorkspaceInviteResponse,
  AddWorkspaceDomainRequest,
  AddWorkspaceDomainResponse,
  AddWorkspaceUserRequest,
  AddWorkspaceUserResponse,
  CreateWorkspaceRequest,
  CreateWorkspaceResponse,
  GetUserWorkspaceRequest,
  GetUserWorkspaceResponse,
  GetWorkspaceInfoRequest,
  GetWorkspaceInfoResponse,
  GetWorkspaceInvitesRequest,
  GetWorkspaceInvitesResponse,
  LeaveWorkspaceRequest,
  LeaveWorkspaceResponse,
  RejectWorkspaceInviteRequest,
  RejectWorkspaceInviteResponse,
  RemoveWorkspaceDomainRequest,
  RemoveWorkspaceUserRequest,
  RemoveWorkspaceUserResponse,
  UpdateWorkspaceRequest,
  UpdateWorkspaceResponse,
  WorkspaceDomain,
  WorkspaceInvite,
  Workspaces,
  WorkspaceUser,
} from '@interfaces/workspace.interface';
import axios, { AxiosInstance, CancelTokenSource, HttpStatusCode } from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import { toast } from 'react-toastify';

export class HttpApi extends BaseApi {
  private static _instance: HttpApi;
  private readonly isDevEnvironment = process && process.env.NODE_ENV === 'development';

  private headers = {
    'Client-Type': 'co.lexai.lexai-webapp',
    'Client-Version': process.env.NEXT_PUBLIC_APP_VERSION,
    'Client-OS-Type': 'web',
    'Client-OS-Version': '',
    'Client-Device': '',
  };

  private axiosCachedInstance: AxiosInstance;

  private constructor() {
    super();

    const instance = axios.create();
    this.axiosCachedInstance = setupCache(instance, {
      methods: ['get', 'post'],
      cachePredicate: { statusCheck: (status) => status === 200 },
      ttl: 1000 * 60 * 5,
    });
  }

  public static get Instance(): HttpApi {
    return this._instance || (this._instance = new HttpApi());
  }

  async getSubscriptions(): Promise<Offering[]> {
    try {
      const url: string = Urls.getSubscriptions;

      const res = await this.axiosCachedInstance.get<GetSubscriptionsResponse>(url, {
        headers: this.headers,
      });
      return res.data.offerings;
    } catch (error) {
      return null;
    }
  }

  async signIn(email: string, password: string): Promise<SignInResponse> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      const url: string = Urls.signIn;
      const body: SignInRequest = {
        email,
        password,
      };

      const res = await axios.post<SignInResponse>(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });
      return res.data;
    } catch (error) {
      return null;
    }
  }

  async signOut(): Promise<SignOutResponse> {
    try {
      const url: string = Urls.signOut;
      const body: SignOutRequest = {};

      const res = await axios.post<SignOutResponse>(url, body, {
        headers: this.headers,
      });
      return res.data;
    } catch (error) {
      return null;
    }
  }

  async validateToken(): Promise<ValidateTokenResponse> {
    try {
      const url: string = Urls.validateToken;
      const body: ValidateTokenRequest = {};

      const res = await axios.post<ValidateTokenResponse>(url, body, {
        headers: this.headers,
      });
      return res.data;
    } catch (error) {
      return null;
    }
  }

  async getProfile(): Promise<Profile> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();

      const url: string = Urls.getProfile;
      const body: GetProfileRequest = {};

      const res = await axios.post<GetProfileResponse>(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });
      return res.data.profile;
    } catch (error) {
      return null;
    }
  }

  async updateProfile(profile: Profile): Promise<Profile> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      const body: UpdateProfileRequest = {
        profile,
      };

      const url: string = Urls.updateProfile;
      const res = await this.axiosCachedInstance.post<UpdateProfileResponse>(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });
      return res.data.profile;
    } catch (error) {
      return null;
    }
  }

  async getAuthors(): Promise<Author[]> {
    try {
      const url: string = Urls.getAuthors;
      const body: GetAuthorsRequest = {};

      const res = await this.axiosCachedInstance.post<GetAuthorsResponse>(url, body, {
        headers: this.headers,
      });

      return res.data.authors;
    } catch (error) {
      return null;
    }
  }

  async beginTrial(): Promise<BeginTrialResponse> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      const url: string = Urls.beginTrial;
      const body: BeginTrialRequest = {};

      const res = await this.axiosCachedInstance.post(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });
      return res.data;
    } catch (error) {
      return null;
    }
  }

  async checkTrialStatus(): Promise<CheckTrialStatusResponse> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      const url: string = Urls.checkTrialStatus;
      const body: CheckTrialStatusRequest = {};

      const res = await this.axiosCachedInstance.post(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });
      const response: CheckTrialStatusResponse = <CheckTrialStatusResponse>res.data;
      return response;
    } catch (error) {
      return null;
    }
  }

  async deleteAccount(): Promise<boolean> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      const url: string = Urls.deleteAccount;
      const body: DeleteAccountRequest = {};

      const res = await this.axiosCachedInstance.post(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });
      const response: DeleteAccountResponse = <DeleteAccountResponse>res.data;

      return response.success;
    } catch (error) {
      return null;
    }
  }

  async resetPassword(): Promise<boolean> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      const url: string = Urls.resetPassword;
      const body: BasicRequest = {};

      const res = await axios.post(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });
      const response: BasicResponse = <BasicResponse>res.data;

      return response.success;
    } catch (error) {
      return null;
    }
  }

  async getPricingTable(): Promise<[]> {
    const url: string = Urls.getPricingTable;
    const res = await axios.get(url);
    return res.data;
  }

  async getContentConfig(): Promise<ContentConfig> {
    let reqHeaders = {};
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      reqHeaders = { ...this.headers, 'auth-token': token };
    } catch (err) {
      reqHeaders = this.headers;
    }

    try {
      const url: string = Urls.getContentConfig;
      const body: GetContentConfigRequest = {};

      const res = await this.axiosCachedInstance.post(url, body, {
        headers: reqHeaders,
      });

      const response: GetContentConfigResponse = <GetContentConfigResponse>res.data;

      return response['content_config'];
    } catch (error) {
      return null;
    }
  }

  async getFeedListing(page_number: number, filters?: ContentFilter): Promise<GetFeedResponse> {
    let reqHeaders = {};
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      reqHeaders = { ...this.headers, 'auth-token': token };
    } catch (err) {
      reqHeaders = this.headers;
    }

    try {
      const url: string = Urls.getFeedListing;
      const request: GetFeedRequest = {
        page_number,
        page_size: Number.parseInt(process.env.NEXT_PUBLIC_PAGINATION_SIZE),
        filter: filters,
      };

      const res = await axios.post(url, request, {
        headers: reqHeaders,
      });

      const response: GetFeedResponse = <GetFeedResponse>res.data;
      return { posts: response.posts, totalData: response.totalData };
    } catch (error) {
      console.log(error);
      return { posts: [], totalData: 0 };
    }
  }

  async getFeedSearch(request: GetFeedSearchRequest): Promise<GetFeedSearchResponse> {
    let reqHeaders = {};
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      reqHeaders = { ...this.headers, 'auth-token': token };
    } catch (err) {
      reqHeaders = this.headers;
    }

    try {
      const url: string = Urls.feedSearch;
      const res = await axios.post(url, request, {
        headers: reqHeaders,
      });

      const response: GetFeedSearchResponse = <GetFeedSearchResponse>res.data;
      return { posts: response.posts, totalData: response.totalData };
    } catch (error) {
      console.log(error);
      return { posts: [], totalData: 0 };
    }
  }

  async getSearchChunks(
    request: GetFeedSearchRequest,
    cancelTokenSource?: CancelTokenSource
  ): Promise<GetFeedSearchResponse> {
    let reqHeaders = {};

    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      reqHeaders = { ...this.headers, 'auth-token': token };
    } catch (err) {
      reqHeaders = this.headers;
    }

    try {
      const url: string = Urls.SearchChunks;
      const res = await axios.post(url, request, {
        headers: reqHeaders,
        cancelToken: cancelTokenSource?.token,
      });

      const response: GetFeedSearchResponse = <GetFeedSearchResponse>res.data;
      return { posts: response.posts, totalData: response.totalData };
    } catch (error) {
      console.log(error);
      return { posts: [], totalData: 0 };
    }
  }

  async getPost(id?: string, itemUrl?: string): Promise<PostItem> {
    let reqHeaders = {};
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      reqHeaders = { ...this.headers, 'auth-token': token };
    } catch (err) {
      reqHeaders = this.headers;
    }

    try {
      const url: string = Urls.getPost;
      const body: GetPostRequest = {
        post_id: id,
        item_url: itemUrl,
      };

      const res = await this.axiosCachedInstance.post<GetPostResponse>(url, body, {
        headers: reqHeaders,
      });
      return res.data.post;
    } catch (error) {
      return null;
    }
  }

  async putPost(post: Post): Promise<string> {
    try {
      const url: string = Urls.putPost;
      const body: PutPostRequest = {
        post: post,
      };

      const res = await this.axiosCachedInstance.post(url, body, {
        headers: this.headers,
      });
      return res.data;
    } catch (error) {
      return null;
    }
  }

  async getCollections(lastId: string, pageSize: number): Promise<LibraryCollection[]> {
    let reqHeaders = {};
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();

      const url: string = Urls.getCollections;
      const body: GetCollectionsRequest = {
        last_id: lastId,
        page_size: pageSize,
      };

      reqHeaders = { ...this.headers, 'auth-token': token };

      const res = await this.axiosCachedInstance.post<GetCollectionsResponse>(url, body, {
        headers: reqHeaders,
      });

      return res.data.collections;
    } catch (error) {
      console.error(error);
    }
  }

  async getLibraryListing(
    lastId: string,
    pageSize?: number,
    filter?: ContentFilter,
    fetchCollections?: boolean
  ): Promise<LibraryCollection[]> {
    let reqHeaders = {};
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();

      const url: string = Urls.getLibraryListing;
      const body: GetLibraryListingRequest = {
        last_id: lastId,
        page_size: pageSize,
        filter: filter,
        fetch_collections: fetchCollections,
      };

      reqHeaders = { ...this.headers, 'auth-token': token };

      const res = await this.axiosCachedInstance.post<GetLibraryListingResponse>(url, body, {
        headers: reqHeaders,
      });

      return res.data.collections;
    } catch (error) {
      console.error(error);
    }
  }

  async getSummaryCollection(id: string): Promise<SummaryCollection> {
    let reqHeaders = {};
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      reqHeaders = { ...this.headers, 'auth-token': token };
    } catch (err) {
      reqHeaders = this.headers;
    }

    try {
      const url: string = Urls.getSummaryCollection;
      const body: GetSummaryCollectionRequest = {
        summary_collection_id: id,
        summaryCollectionId: id,
      };

      const res = await this.axiosCachedInstance.post(url, body, {
        headers: reqHeaders,
      });

      const response: GetSummaryCollectionResponse = <GetSummaryCollectionResponse>res.data;
      return response.summary_collection;
    } catch (error) {
      return null;
    }
  }

  async getSummaries(lastPostId: string, filters?: ContentFilter, pageSize?: number): Promise<LibraryItem[]> {
    let reqHeaders = {};

    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      reqHeaders = { ...this.headers, 'auth-token': token };
    } catch (err) {
      reqHeaders = this.headers;
    }

    try {
      const url: string = Urls.getSummaries;
      const request: GetSummariesRequest = {
        last_id: lastPostId,
        page_size: pageSize || Number.parseInt(process.env.NEXT_PUBLIC_PAGINATION_SIZE),
        filter: filters,
      };

      const res = await this.axiosCachedInstance.post(url, request, {
        headers: reqHeaders,
      });

      const response: GetSummariesResponse = <GetSummariesResponse>res.data;
      return response.summaries;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  async getSummary(id: string): Promise<Summary> {
    let reqHeaders = {};
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      reqHeaders = { ...this.headers, 'auth-token': token };
    } catch (err) {
      reqHeaders = this.headers;
    }

    try {
      const url: string = Urls.getSummary;
      const body: GetSummaryRequest = { id: id };

      const res = await axios.post(url, body, {
        headers: reqHeaders,
      });
      const response: GetSummaryResponse = <GetSummaryResponse>res.data;
      return response['summary'];
    } catch (error) {
      return null;
    }
  }

  async getLexcDocument(id: string): Promise<Document> {
    try {
      const url: string = Urls.getDocument;
      const body: GetDocumentRequest = { id: id };

      const res = await this.axiosCachedInstance.post(url, body, {
        headers: this.headers,
      });
      return res.data;
    } catch (error) {
      return null;
    }
  }

  async getNewsFeedCollection(getNewsFeedCollectionRequest: GetNewsFeedCollectionRequest): Promise<NewsFeedCollection> {
    try {
      const url: string = Urls.getNewsFeedCollection;
      const body: GetNewsFeedCollectionRequest = {};

      const res = await this.axiosCachedInstance.post(url, body, {
        headers: this.headers,
      });

      const response: GetNewsFeedCollectionResponse = <GetNewsFeedCollectionResponse>res.data;
      return response['news_feed_collection'];
    } catch (error) {
      return null;
    }
  }

  async getBadges(): Promise<Badge[]> {
    let reqHeaders = {};
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      reqHeaders = { ...this.headers, 'auth-token': token };
    } catch (err) {
      reqHeaders = this.headers;
    }

    try {
      const url: string = Urls.getBadges;
      const body: GetBadgeRequest = {};

      const res = await this.axiosCachedInstance.post(url, body, {
        headers: reqHeaders,
      });
      const res2 = await this.axiosCachedInstance.post(url, body, {
        headers: reqHeaders,
      });

      const response: GetBadgeResponse = <GetBadgeResponse>res.data;
      return response['badges'];
    } catch (error) {
      return null;
    }
  }

  async getCategories(): Promise<PostCategory[]> {
    let reqHeaders = {};
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      reqHeaders = { ...this.headers, 'auth-token': token };
    } catch (err) {
      reqHeaders = this.headers;
    }

    try {
      const url: string = Urls.getCategories;
      const body: GetCategoryRequest = {};

      const res = await this.axiosCachedInstance.post(url, body, {
        headers: reqHeaders,
      });

      const response: GetCategoryResponse = <GetCategoryResponse>res.data;

      return response['categories'];
    } catch (error) {
      return null;
    }
  }

  async getHashtags(): Promise<PostHashTag[]> {
    let reqHeaders = {};
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      reqHeaders = { ...this.headers, 'auth-token': token };
    } catch (err) {
      reqHeaders = this.headers;
    }

    try {
      const url: string = Urls.getHashtags;
      const body: GetHashtagRequest = {};

      const res = await this.axiosCachedInstance.post(url, body, {
        headers: reqHeaders,
      });

      const response: GetHashtagResponse = <GetHashtagResponse>res.data;

      return response['hashtags'];
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async addSavedPost(post: Post, areas: KnowledgeArea[], areaId?: string): Promise<boolean> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      const url: string = Urls.addSavedPost;
      const body: AddSavedPostRequest = {
        area_id: areaId ?? null,
        post: post,
        areas,
      };

      const res = await this.axiosCachedInstance.post(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });

      const response: AddSavedPostResponse = <AddSavedPostResponse>res.data;
      return response['success'];
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async addNoteToArea(noteData: AddNotePostRequest): Promise<boolean> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      const url: string = Urls.addNoteToArea;
      const body: AddNotePostRequest = noteData;

      const res = await axios.post(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });

      const response: AddSavedPostResponse = <AddSavedPostResponse>res.data;
      return response['success'];
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async removeSavedPost(saveId: string, post?: Post): Promise<boolean> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      const url: string = Urls.removeSavedPost;
      const body: RemoveSavedPostRequest = {
        save_id: saveId,
      };
      if (post) {
        body.post = post;
      }

      const res = await this.axiosCachedInstance.post(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });

      const response: RemoveSavedPostResponse = <RemoveSavedPostResponse>res.data;
      return response['success'];
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async getKnowledgeAreas(areaId?: string): Promise<KnowledgeArea[]> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      const url: string = Urls.getKnowledgeAreas;
      const body: GetKnowledgeAreasRequest = {
        area_id: areaId,
      };

      const res = await axios.post(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });

      const response: GetKnowledgeAreasResponse = <GetKnowledgeAreasResponse>res.data;
      return response['knowledge_area'];
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async getSavedPosts(areaId?: string): Promise<SavePosts[]> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      const url: string = Urls.getSavedPosts;
      const body: GetSavedPostsRequest = { area_id: areaId };

      const res = await axios.post(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });

      const response: GetSavedPostsResponse = <GetSavedPostsResponse>res.data;
      return response['saved_posts'];
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async putKnowledgeArea(knowledgeArea: KnowledgeArea): Promise<KnowledgeArea> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      const url: string = Urls.putKnowledgeArea;
      const body: PutKnowledgeAreaRequest = { knowledge_area: knowledgeArea };

      const res = await this.axiosCachedInstance.post<PutKnowledgeAreaResponse>(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });
      return res.data.knowledge_area;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async deleteKnowledgeArea(areaId: string): Promise<boolean> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      const url: string = Urls.deleteKnowledgeArea;
      const body: DeleteKnowledgeAreaRequest = { area_id: areaId };
      const res = await this.axiosCachedInstance.post<DeleteAccountResponse>(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });
      return res.data.success;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  //#region Workspace APIs
  async getUserWorkspace(): Promise<Workspaces> {
    let reqHeaders = {};
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      reqHeaders = { ...this.headers, 'auth-token': token };
    } catch (err) {
      reqHeaders = this.headers;
    }

    const url: string = Urls.getUserWorkspace;

    try {
      const request: GetUserWorkspaceRequest = {};
      const res = await this.axiosCachedInstance.post<GetUserWorkspaceResponse>(url, request, {
        headers: reqHeaders,
      });
      return res.data.workspace;
    } catch (error) {
      if (this.isDevEnvironment) {
        console.log(error);
      }
      return null;
    }
  }

  async getWorkspaceInfo(workspaceId: number): Promise<Workspaces> {
    let reqHeaders = {};
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      reqHeaders = { ...this.headers, 'auth-token': token };
    } catch (err) {
      reqHeaders = this.headers;
    }

    const url: string = Urls.getWorkspaceInfo;

    try {
      const request: GetWorkspaceInfoRequest = {
        workspace_id: `${workspaceId}`,
      };
      const res = await this.axiosCachedInstance.post<GetWorkspaceInfoResponse>(url, request, {
        headers: reqHeaders,
      });
      return res.data.workspace;
    } catch (error) {
      if (this.isDevEnvironment) {
        console.log(error);
      }
      return null;
    }
  }

  async createWorkspace(workspace: Workspaces): Promise<Workspaces> {
    let reqHeaders = {};
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      reqHeaders = { ...this.headers, 'auth-token': token };
    } catch (err) {
      reqHeaders = this.headers;
    }

    const url: string = Urls.createWorkspace;

    try {
      const request: CreateWorkspaceRequest = {
        workspace: workspace,
      };
      const res = await this.axiosCachedInstance.post<CreateWorkspaceResponse>(url, request, {
        headers: reqHeaders,
      });
      return res.data.workspace;
    } catch (error) {
      if (this.isDevEnvironment) {
        console.log(error);
      }
      return null;
    }
  }

  async updateWorkspace(workspace: Workspaces): Promise<Workspaces> {
    let reqHeaders = {};
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      reqHeaders = { ...this.headers, 'auth-token': token };
    } catch (err) {
      reqHeaders = this.headers;
    }

    const url: string = Urls.updateWorkspace;

    try {
      const request: UpdateWorkspaceRequest = {
        workspace: workspace,
      };
      const res = await this.axiosCachedInstance.post<UpdateWorkspaceResponse>(url, request, {
        headers: reqHeaders,
      });
      return res.data.workspace;
    } catch (error) {
      if (this.isDevEnvironment) {
        console.log(error);
      }
      return null;
    }
  }

  async addWorkspaceUser(workspaceUser: WorkspaceUser): Promise<WorkspaceUser> {
    let reqHeaders = {};
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      reqHeaders = { ...this.headers, 'auth-token': token };
    } catch (err) {
      reqHeaders = this.headers;
    }

    const url: string = Urls.addWorkspaceUser;

    try {
      const request: AddWorkspaceUserRequest = {
        workspace_user: workspaceUser,
      };
      const res = await this.axiosCachedInstance.post<AddWorkspaceUserResponse>(url, request, {
        headers: reqHeaders,
      });
      return res.data.workspace_user;
    } catch (error) {
      if (this.isDevEnvironment) {
        console.log(error);
      }
      if (error?.response?.data?.reason?.response?.reason) toast.error(error.response.data.reason.response.reason);
      else {
        toast.error('An error occured, please try again later');
      }
      return null;
    }
  }

  async removeWorkspaceUser(workspaceUser: WorkspaceUser): Promise<boolean> {
    let reqHeaders = {};
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      reqHeaders = { ...this.headers, 'auth-token': token };
    } catch (err) {
      reqHeaders = this.headers;
    }

    const url: string = Urls.removeWorkspaceUser;

    try {
      const request: RemoveWorkspaceUserRequest = {
        workspace_user: workspaceUser,
      };
      const res = await this.axiosCachedInstance.post<RemoveWorkspaceUserResponse>(url, request, {
        headers: reqHeaders,
      });
      return res.data.success;
    } catch (error) {
      if (this.isDevEnvironment) {
        console.log(error);
      }
      return null;
    }
  }

  async addWorkspaceDomain(domain: WorkspaceDomain): Promise<WorkspaceDomain> {
    let reqHeaders = {};
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      reqHeaders = { ...this.headers, 'auth-token': token };
    } catch (err) {
      reqHeaders = this.headers;
    }

    const url: string = Urls.addWorkspaceDomain;

    try {
      const request: AddWorkspaceDomainRequest = {
        domain: domain,
      };
      const res = await this.axiosCachedInstance.post<AddWorkspaceDomainResponse>(url, request, {
        headers: reqHeaders,
      });
      return res.data.domain;
    } catch (error) {
      if (this.isDevEnvironment) {
        console.log(error);
      }
      return null;
    }
  }

  async removeWorkspaceDomain(domain: WorkspaceDomain): Promise<boolean> {
    let reqHeaders = {};
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      reqHeaders = { ...this.headers, 'auth-token': token };
    } catch (err) {
      reqHeaders = this.headers;
    }

    const url: string = Urls.removeWorkspaceDomain;

    try {
      const request: RemoveWorkspaceDomainRequest = {
        domain: domain,
      };
      const res = await this.axiosCachedInstance.post<RemoveWorkspaceUserResponse>(url, request, {
        headers: reqHeaders,
      });
      return res.data.success;
    } catch (error) {
      if (this.isDevEnvironment) {
        console.log(error);
      }
      return null;
    }
  }

  async getWorkspaceInvites(): Promise<WorkspaceInvite[]> {
    let reqHeaders = {};
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      reqHeaders = { ...this.headers, 'auth-token': token };
    } catch (err) {
      reqHeaders = this.headers;
    }

    const url: string = Urls.getWorkspaceInvites;

    try {
      const request: GetWorkspaceInvitesRequest = {};

      const res = await this.axiosCachedInstance.post<GetWorkspaceInvitesResponse>(url, request, {
        headers: reqHeaders,
      });
      return res.data.invites;
    } catch (error) {
      if (this.isDevEnvironment) {
        console.log(error);
      }
      return null;
    }
  }

  async acceptWorkspaceInvite(workspaceId: string): Promise<boolean> {
    let reqHeaders = {};
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      reqHeaders = { ...this.headers, 'auth-token': token };
    } catch (err) {
      reqHeaders = this.headers;
    }

    const url: string = Urls.acceptWorkspaceInvite;

    try {
      const request: AcceptWorkspaceInviteRequest = {
        workspace_id: workspaceId,
      };
      const res = await this.axiosCachedInstance.post<AcceptWorkspaceInviteResponse>(url, request, {
        headers: reqHeaders,
      });
      return res.data.success;
    } catch (error) {
      if (this.isDevEnvironment) {
        console.log(error);
      }
      return null;
    }
  }

  async rejectWorkspaceInvite(workspaceId: string): Promise<boolean> {
    let reqHeaders = {};
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      reqHeaders = { ...this.headers, 'auth-token': token };
    } catch (err) {
      reqHeaders = this.headers;
    }

    const url: string = Urls.rejectWorkspaceInvite;

    try {
      const request: RejectWorkspaceInviteRequest = {
        workspace_id: workspaceId,
      };
      const res = await this.axiosCachedInstance.post<RejectWorkspaceInviteResponse>(url, request, {
        headers: reqHeaders,
      });
      return res.data.success;
    } catch (error) {
      if (this.isDevEnvironment) {
        console.log(error);
      }
      return null;
    }
  }

  async leaveWorkspace(workspaceId: string): Promise<boolean> {
    let reqHeaders = {};
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      reqHeaders = { ...this.headers, 'auth-token': token };
    } catch (err) {
      reqHeaders = this.headers;
    }

    const url: string = Urls.leaveWorkspace;

    try {
      const request: LeaveWorkspaceRequest = {
        workspace_id: workspaceId,
      };
      const res = await this.axiosCachedInstance.post<LeaveWorkspaceResponse>(url, request, {
        headers: reqHeaders,
      });
      return res.data.success;
    } catch (error) {
      if (this.isDevEnvironment) {
        console.log(error);
      }
      if (error?.response?.data?.reason?.response?.reason) toast.error(error.response.data.reason.response.reason);
      else {
        toast.error('An error occured, please try again later');
      }
      return null;
    }
  }

  async getOrganizations(): Promise<Organization[]> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      const url: string = Urls.getOrganizations;
      const body: GetOrganizationsRequest = {};

      const res = await this.axiosCachedInstance.post<GetOrganizationsResponse>(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });

      return res.data.organizations;
    } catch (error) {
      return null;
    }
  }

  async getOrganization(urlSlug: string): Promise<Organization> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      const url: string = Urls.getOrganization;
      const body: GetOrganizationRequest = {
        url_slug: urlSlug,
      };

      const res = await this.axiosCachedInstance.post<GetOrganizationResponse>(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });

      return res.data.organization;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  //#endregion

  //#billing Payment API
  async getBillingInfo(): Promise<BillingInfoResponse> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();

      const url: string = Urls.getBillingInfo;
      const body: BillingInfoRequest = {};

      const res = await this.axiosCachedInstance.post<BillingInfoResponse>(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });
      return res.data;
    } catch (error) {
      return null;
    }
  }

  async updateBillingInfo(billingData: UpdateBillingInfoRequest): Promise<UpdateBillingInfoResponse> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      const body: UpdateBillingInfoRequest = billingData;
      const url: string = Urls.updateBillingInfo;
      const res = await this.axiosCachedInstance.post<UpdateBillingInfoResponse>(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });
      return res.data;
    } catch (error) {
      return null;
    }
  }
  async getInvoices(): Promise<GetInvoicesResponse> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();

      const url: string = Urls.getInvoices;
      const body: GetInvoicesRequest = {};

      const res = await this.axiosCachedInstance.post<GetInvoicesResponse>(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });
      return res.data;
    } catch (error) {
      return null;
    }
  }

  //#endbilling

  //#notification API
  async getNotifications(): Promise<GetUserNotificationsResponse> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      const url: string = Urls.getNotifications;
      const res = await this.axiosCachedInstance.get<GetUserNotificationsResponse>(
        url,

        {
          headers: { ...this.headers, 'auth-token': token },
        }
      );
      return res.data;
    } catch (error) {
      return null;
    }
  }

  async getNotificationsV3(): Promise<GetUserNotificationsV3Response> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      const url: string = Urls.getNotificationsV3;
      const body: GetUserNotificationsV3Request = {};
      const res = await this.axiosCachedInstance.post<GetUserNotificationsV3Response>(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });
      return res.data;
    } catch (error) {
      return null;
    }
  }

  async updateNotificationStatus(
    notificationId: UpdateNotificationStatusRequest
  ): Promise<UpdateNotificationStatusResponse> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();
      const body: UpdateNotificationStatusRequest = notificationId;
      const url: string = Urls.updateNotifications;
      const res = await this.axiosCachedInstance.post<UpdateNotificationStatusResponse>(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });
      return res.data;
    } catch (error) {
      return null;
    }
  }
  //#notification
  //#common API
  async getCountries(): Promise<GetCountriesResponse> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();

      const url: string = Urls.getCountries;
      const body: GetCountriesRequest = {};

      const res = await this.axiosCachedInstance.post<GetCountriesResponse>(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });
      return res.data;
    } catch (error) {
      return null;
    }
  }
  //#common

  //#Notification Filter CRUD API
  async getNotificationFilters(): Promise<GetNotificationFiltersResponse> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();

      const url: string = Urls.getAllNotificationFilters;
      const body: GetNotificationFiltersRequest = {};

      const res = await axios.post<GetNotificationFiltersResponse>(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });
      return res.data;
    } catch (error) {
      return null;
    }
  }

  async addNotificationFilter(filterData: PutNotificationFilterRequest): Promise<PutResponse> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();

      const url: string = Urls.addNotificationFilter;
      const body: PutNotificationFilterRequest = filterData;

      const res = await this.axiosCachedInstance.post<PutResponse>(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });
      return res.data;
    } catch (error) {
      return null;
    }
  }

  async updateNotificationFilter(filterData: UpdateNotificationFilterRequest): Promise<PutResponse> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();

      const url: string = Urls.updateNotificationFilter;
      const body: UpdateNotificationFilterRequest = filterData;

      const res = await this.axiosCachedInstance.post<PutResponse>(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });
      return res.data;
    } catch (error) {
      return null;
    }
  }

  async deleteNotificationFilter(filterId: DeleteNotificationFilterRequest): Promise<PutResponse> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();

      const url: string = Urls.deleteNotificationFilter;
      const body: DeleteNotificationFilterRequest = filterId;

      const res = await this.axiosCachedInstance.post<PutResponse>(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });
      return res.data;
    } catch (error) {
      return null;
    }
  }
  //#Notification Filter CRUD

  async ragQueryApiProxy(query: RagQuery): Promise<RagResponse> {
    try {
      const res = await axios.post(Urls.mlProxyRagQuery, query);

      if (res.status == HttpStatusCode.Ok) {
        return res.data;
      }

      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async getSearchFeedListing(data: GetFeedSearchRequest): Promise<GetFeedSearchResponse> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();

      const url: string = Urls.getSearchFeedListing;
      const body: GetFeedSearchRequest = data;

      const res = await axios.post<GetFeedSearchResponse>(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });
      return res.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  async getMonthlyQuota(): Promise<number | null> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();

      const url: string = Urls.monthlyQuotaAPI;
      const res = await axios.get<{ remainingPercentage?: number }>(url, {
        headers: { ...this.headers, 'auth-token': token },
      });
      return res.data?.remainingPercentage | 0;
    } catch (error) {
      console.debug(error);
      return null;
    }
  }

  async chatbotMessage(req: ChatbotRequest): Promise<ChatbotRespond> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();

      const url: string = Urls.chatbotAPI;
      const body: ChatbotRequest = req;

      const res = await axios.post<ChatbotRespond>(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });
      return res.data;
    } catch (error) {
      console.debug(error);
      const data = error?.response?.data;
      if (data?.error_code === 'token_limitation')
        return {
          status: false,
          isTokenLimit: true,
          response: data?.reason,
        };
      return null;
    }
  }

  async getChatHistory(req: ChatbotHistoryRequest): Promise<ChatbotHistoryRespond> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();

      const url: string = Urls.chatbotHistoryAPI;

      const res = await axios.get<ChatbotHistoryRespond>(url, {
        params: {
          resourceId: req.resourceId,
          resourceType: req.resourceType,
        },
        headers: { ...this.headers, 'auth-token': token },
      });
      return res.data;
    } catch (error) {
      console.debug(error);
      return null;
    }
  }

  async saveChatbotMessage(req: SaveChatbotMessageRequest): Promise<SaveChatbotMessageRespond> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();

      const url: string = Urls.saveChatbotMessageAPI;
      const body: SaveChatbotMessageRequest = req;

      const res = await axios.post<SaveChatbotMessageRespond>(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });
      return res.data;
    } catch (error) {
      console.debug(error);
      return null;
    }
  }

  async saveUserFile(req: saveUserFileRequest): Promise<saveUserFileRespond> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();

      const url: string = Urls.saveUserFileAPI;
      const body: saveUserFileRequest = req;

      const res = await axios.post<saveUserFileRespond>(url, body, {
        headers: { ...this.headers, 'auth-token': token },
      });
      return res.data;
    } catch (error) {
      console.debug(error);
      return null;
    }
  }

  async fileUpload(req: fileUploadRequest): Promise<fileUploadRespond> {
    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();

      const url: string = Urls.fileUploadAPI;

      let data = new FormData();
      data.append('file', req.file);

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: { ...this.headers, 'auth-token': token },
        url,
        data,
      };

      const res = await axios.request(config);
      return res.data;
    } catch (error) {
      console.debug(error);
      return null;
    }
  }
}
